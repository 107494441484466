<template>
  <div class="row-action-container"></div>
</template>

<script>
export default {
  props: {
    record: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    platList: {
      default: () => [],
      type: Array
    }
  }
}
</script>

<style></style>
